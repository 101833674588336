<template>
  <div class="login text-center ma mt-8">
    <form @submit.prevent="submit" :id="form_id">
      <div class="rounder">
        <div class="card_header ubuntu_b">
            <v-btn color="#eee" fab x-small depressed class="white_circle back mob" @click="back"><v-img :src="require(`@/assets/img/left_red.png`)" contain></v-img></v-btn>
            Promjeni Email
        </div>
        <div class="input_holder border_top">
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            :label="t_email"
            required
            v-eager
            type="email"
            solo
            @input="check_email"
            @blur="check_email"
          ></v-text-field>
        </div>
        <div class="input_holder border_top">
          <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            :label="t_password"
            solo
            v-eager
            :type="(password.length)?`password`:`text`"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>
        </div>
        <v-btn class="rounder_bottom submit_btn ubuntu_b" color="secondary" type="submit" :disabled="submitStatus === 'PENDING'">
          Promjeni Email
          <div class="white_circle"><v-img :src="require(`@/assets/img/right_red.png`)" contain></v-img></div>
        </v-btn>
      </div>
    </form>
    <div class="text-center pt-2">
        <p class="typo__p" v-if="submitStatus === 'OK'">Poslana je link za potvrdu na Vašu Email adresu</p>
        <p class="typo__p error-txt" v-if="submitStatus === 'ERROR'">Molimo Vas da ispunite formu pravilno</p>
        <p class="typo__p" v-if="submitStatus === 'PENDING'">Šaljem...</p>
        <p class="typo__p error-txt" v-if="submitError !== ''">{{ submitError }}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
//import { conf } from '@/config.js'
//import EventBus from '@/event-bus'
import { validationMixin } from 'vuelidate'
import { email, required, minLength } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      form_id: 'changeEmail',
      password: '',
      email:'',
      email_val: true,
      form_enabled: false,
      t_email: 'Novi email',
      t_password: 'Lozinka'
    }
  },
  mixins: [validationMixin],
  validations: {
    email: {
      email,
      required,
    },
    password: {
      required,
      minLength: minLength(6),
    }
  },
  computed: {
    ...mapGetters({
      submitStatus: 'forms/submitStatus',
      submitError: 'forms/submitError',
      user_id: 'user/userId',
    }),
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('Lozinka mora sadžati barem 6 znakova')
      !this.$v.password.required && errors.push('Lozinka je obavezna')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.required && errors.push('Email je obavezan')
      !this.$v.email.email && errors.push('Nevažeći Email')
      return errors
    },
  },
  methods: {
    submit () {
      var formPass = false
      this.$v.$touch()
      formPass = this.$v.$invalid
      if (formPass) {
        this.$store.dispatch('forms/changeSubmitStatus','ERROR')
      } else {
        this.$store.dispatch('forms/changeSubmitStatus','PENDING')
        let formData = {
          password: this.password,
          email: this.email,
          user_id: this.user_id
        }
        this.$store.dispatch('forms/submitForm', { form: this.form_id, data: formData })
      }
    },
    back(){
        this.$router.go(-1)
    }
  },
  destroyed(){
      this.$store.dispatch('forms/changeSubmitStatus',null)
      this.$store.dispatch('forms/changeSubmitError',null)
  }
};
</script>
<style scoped>
    form{
      width:100% !important;
    }
    .card_header, .card_content{
        position:relative;
    }
    .card_content{
        padding:10px 20px 20px 50px;
    }
    .card_header{
        background: #FFF;
        padding-left:50px;
        line-height: 47px;
        height:47px;
    }
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
</style>
