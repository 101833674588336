import { render, staticRenderFns } from "./ChangeEmailComponent.vue?vue&type=template&id=b0946f46&scoped=true"
import script from "./ChangeEmailComponent.vue?vue&type=script&lang=js"
export * from "./ChangeEmailComponent.vue?vue&type=script&lang=js"
import style0 from "./ChangeEmailComponent.vue?vue&type=style&index=0&id=b0946f46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0946f46",
  null
  
)

export default component.exports