<template>
  <v-container>
    <v-row dense>
        <v-col lg="6" offset-lg="3" md="10" offset-md="1" cols="12">
            <changeEmailComponent />
        </v-col>
    </v-row>
  </v-container> 
</template>
<script>
import changeEmailComponent from '../components/ChangeEmailComponent.vue'
export default {
    data() {
        return {
        }
    },
    components: { changeEmailComponent },
}
</script>